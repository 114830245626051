<template>
  <div class="absolute bottom-0 left-0 bg-fmu-secondary w-full py-9 flex justify-center">
      <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ModalFooter',
  components: {}
}
</script>

<style scoped>
</style>
