<template>
  <div class="change-mail">
    <el-dialog
        title=""
        v-model="changePasswordModalStore.isOpen"
        width="700px"
        :fullscreen="widthIs('<', 700)"
        top="25vh"
        :close="changePasswordModalStore.resetForm()"
        custom-class="relative">
      <p class="modal-headline text-center md:px-24 font-semibold break-normal">
        Passwort ändern
      </p>
      <div v-if="changePasswordModalStore.errorMsg" class="fmu-error mt-3 mx-12">
        {{ changePasswordModalStore.errorMsg }}
      </div>
      <div class="mt-4 mb-24 md:px-12">
        <input type="password" class="fmu-input mb-2" placeholder="Altes Passwort"
               v-model="changePasswordModalStore.form.oldPw">
        <input type="password" class="fmu-input mb-2" placeholder="Neues Passwort"
               v-model="changePasswordModalStore.form.newPw">
        <input type="password" class="fmu-input mb-2" @keyup.enter="changePw" placeholder="Neues Passwort wiederholen"
               v-model="changePasswordModalStore.form.newPwRepeat">
      </div>
      <br>
      <div class="absolute bottom-0 left-0 bg-fmu-secondary w-full py-9 flex justify-center">
        <fmu-button button-type="outline" @button-click="changePw">Speichern</fmu-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {useChangePasswordModalStore} from "@/store/modals/change-password-modal-store";
import {ElDialog} from "element-plus"
import FmuButton from "@/components/theme/button/FmuButton";
import widthIs, {valuesAreEqual, isEmpty, emptyErrorMsg, passwordsAreNotEqualErrorMsg} from "@/validators/validators"

export default {
  name: 'ChangePasswordModal',
  components: {FmuButton, ElDialog},
  emits: ['button-click'],
  setup() {
    const changePasswordModalStore = useChangePasswordModalStore();

    const changePw = () => {
      if (isEmpty(changePasswordModalStore.form)) {
        changePasswordModalStore.setErrorMsg(emptyErrorMsg)
      } else if (!valuesAreEqual(changePasswordModalStore.newPw, changePasswordModalStore.newPwRepeat)) {
        changePasswordModalStore.setErrorMsg(passwordsAreNotEqualErrorMsg)
      } else {
        changePasswordModalStore.changePw();
      }
    }

    return {changePasswordModalStore, changePw, widthIs}
  }
}
</script>

<style scoped>
</style>
<style>
.change-mail > .el-overlay > .el-dialog {
  height: auto;
}
</style>
