<template>
  <div class="text-headline text-black text-center pt-10 flex px-10 justify-center">
    <div class="fmu-container">
      <div class="text-headline font-condensed font-bold uppercase">
        <slot name="title"></slot>
      </div>
      <div class="text-subline md:px-40">
        <slot name="description">
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import {useUserStore} from "@/store/user/user-store";

export default {
  name: 'Introduction',
  components: {},
  setup() {
    const userStore = useUserStore();
    return {userStore}
  }
}
</script>

<style scoped>
</style>
