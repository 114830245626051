<template>
  <div class="change-mail">
    <el-dialog
        title=""
        v-model="changeEmailModalStore.isOpen"
        :fullscreen="widthIs('<', 700)"
        width="700px"
        top="25vh"
        custom-class="relative">
      <p class="modal-headline text-center md:px-24 font-semibold break-normal">
        E-Mail-Adresse ändern
      </p>
      <div v-if="errorMsg" class="fmu-error mt-3 mx-12">
        {{ errorMsg }}
      </div>
      <form class="mt-4 mb-24 md:px-12" @keyup.enter="changeEmail">
        <input type="text" class="fmu-input mb-2" placeholder="Neue E-Mail-Adresse"
               v-model="changeEmailModalStore.form.email">
        <label for="password" class="text-xs font-semibold">Mit Passwort bestätigen:</label>
        <input type="password" id="password" class="fmu-input mb-2" placeholder="Passwort"
               v-model="changeEmailModalStore.form.password">
      </form>
      <br>
      <div class="absolute bottom-0 left-0 bg-fmu-secondary w-full py-9 flex justify-center">
        <fmu-button button-type="outline" @button-click="changeEmail">Speichern</fmu-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {useChangeEmailModalStore} from "@/store/modals/change-email-modal-store";
import {ElDialog} from "element-plus"
import FmuButton from "@/components/theme/button/FmuButton";
import widthIs, {mailIsValid, emailErrorMsg, isEmpty, emptyErrorMsg} from "@/validators/validators";
import {ref} from "vue"
import axios from "axios";
import {useNotifyStore} from "@/store/notify/notify-store";
import {useUserStore} from "@/store/user/user-store";

export default {
  name: 'ChangeEmailModal',
  components: {FmuButton, ElDialog},
  emits: ['button-click'],
  setup() {
    const changeEmailModalStore = useChangeEmailModalStore();
    const notifyStore = useNotifyStore();
    const userStore = useUserStore();
    let errorMsg = ref('')

    const changeEmail = () => {
      if (!mailIsValid(changeEmailModalStore.form.email)) {
        errorMsg.value = emailErrorMsg
      } else if (isEmpty(changeEmailModalStore.form)) {
        errorMsg.value = emptyErrorMsg
      } else {
        axios.post('user/change-email', {
          form: changeEmailModalStore.form
        }).then(() => {
          notifyStore.notify('Erfolgreich', 'E-Mail-Adresse wurde geändert')
          userStore.reAuth()
          changeEmailModalStore.toggle()
          changeEmailModalStore.reset()
          errorMsg.value = ''
        }).catch(error => {
          errorMsg.value = error.response.data.message;
        })
      }
    }

    return {changeEmailModalStore, errorMsg, changeEmail, widthIs}
  }
}
</script>

<style scoped>
</style>
<style>
.change-mail > .el-overlay > .el-dialog {
  height: auto;
}
</style>
