import { defineStore } from 'pinia'

export const useChangeUserAddressStore = defineStore({
    id: 'changeUserAddressModal',
    state: () => ({
        isOpen: false,
        form: {
            first_name: '',
            last_name: '',
            street: '',
            house_number: '',
            postal_code: '',
            city: ''
        },
        errorMsg: ''
    }),
    getters: {},
    actions: {
        toggle() {
            this.isOpen = !this.isOpen;
        }
    },
})
