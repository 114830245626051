<template>
  <div class="changeUserAddress">
    <el-dialog
        title=""
        v-model="changeUserAddressStore.isOpen"
        :fullscreen="widthIs('<', 700)"
        width="700px"
        top="25vh"
        custom-class="relative">
      <form class="md:px-12" @keyup.enter="changeAddress">
        <p class="font-serif text-25px text-center font-semibold break-normal">
          Rechnungsdetails verwalten
        </p>
        <p class="text-sm text-center hyphens font-light pt-2">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aspernatur corporis deleniti ea esse est ex
          exercitationem illum.
        </p>
        <br>
        <div v-if="errorMsg" class="fmu-error mb-3">
          {{ errorMsg }}
        </div>
        <input type="text" class="fmu-input mb-2"
               placeholder="Firma" v-model="form.company">
        <div class="grid grid-cols-2 gap-2">
          <input type="text" class="fmu-input mb-2"
                 placeholder="Vorname" v-model="form.first_name">
          <input type="text" class="fmu-input mb-2"
                 placeholder="Nachname" v-model="form.last_name">
        </div>
        <div class="flex w-full">
          <div class="w-3/4 mr-2">
            <input type="text" class="fmu-input mb-2 col-span-7"
                   placeholder="Straße" v-model="form.street">
          </div>
          <div class="w-1/4">
            <input type="text" class="fmu-input mb-2"
                   placeholder="Hausnummer" v-model="form.house_number">
          </div>
        </div>
        <div class="flex w-full">
          <div class="w-1/4 mr-2">
            <input type="text" class="fmu-input mb-2"
                   placeholder="PLZ" v-model="form.postal_code" v-mask="'#####'">
          </div>
          <div class="w-3/4">
            <input type="text" class="fmu-input mb-2"
                   placeholder="Stadt" v-model="form.city">
          </div>
        </div>
      </form>
      <modal-footer>
        <fmu-button type="submit" button-type="filled" @button-click="changeAddress">
          <span>Speichern</span>
          <img :src="require('@/assets/icons/chevron_right.svg')" alt="" class="h-5 pl-1 float-right">
        </fmu-button>
      </modal-footer>
    </el-dialog>
  </div>
</template>

<script>
import {ElDialog} from 'element-plus'
import {useChangeUserAddressStore} from "@/store/modals/change-user-address-modal-store";
import {useUserStore} from "@/store/user/user-store";
import {useNotifyStore} from "@/store/notify/notify-store";
import widthIs, {isEmpty, emptyErrorMsg} from "@/validators/validators";
import {mask} from 'vue-the-mask'
import ModalFooter from "@/components/modals/common/ModalFooter";
import FmuButton from "@/components/theme/button/FmuButton";
import axios from "axios";
import {ref} from "vue"

export default {
  name: 'ChangeUserAddressModal',
  components: {
    FmuButton,
    ModalFooter,
    ElDialog,
  },
  emits: ['button-click'],
  setup() {
    const changeUserAddressStore = useChangeUserAddressStore();
    const userStore = useUserStore();
    const notifyStore = useNotifyStore();

    const form = userStore.user.user_address;
    const errorMsg = ref('')

    const changeAddress = () => {
      if (!isEmpty(form)) {
        axios.post('user/change-address', {
          form: form
        }).then(() => {
          notifyStore.notify('Erfolgreich', 'Rechnungsdetails wurden geändert')
          changeUserAddressStore.toggle()
          errorMsg.value = ''
        })
      } else {
        errorMsg.value = emptyErrorMsg
      }
    }

    return {
      errorMsg,
      changeAddress,
      mask,
      form,
      widthIs,
      changeUserAddressStore
    }
  }
}
</script>

<style>
.changeUserAddress > .el-overlay > .el-dialog {
  height: 520px;
}
</style>
<style scoped>
</style>
