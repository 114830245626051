<template>
  <introduction>
    <template #title>Kontoeinstellungen</template>
    <template #description>
    </template>
  </introduction>
  <div class="py-6 md:py-24 md:flex justify-center px-10">
    <div>
      <div class="grid grid-cols-1 md:grid-cols-2">
        <div class="font-semibold text-xl pr-12">Zugangsdaten bearbeiten</div>
        <div>
          <div class="font-semibold text-sm pt-4 md:pt-1 w-full">
            <span>{{ userStore.user.email }}</span>
            <img :src="require('@/assets/icons/bearbeiten.svg')" alt="" class="h-7 float-right pl-6 cursor-pointer"
                 @click="changeEmailModalStore.toggle()">
          </div>
          <div class="text-sm font-thin pt-2 pb-5">
            Passwort: ********
            <img :src="require('@/assets/icons/bearbeiten.svg')" alt="" class="h-7 float-right pl-6 cursor-pointer"
                 @click="changePasswordModalStore.toggle()">
          </div>
          <div class="border-b border-black"></div>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 pt-10">
        <div class="font-semibold text-xl">gebuchte Produkte</div>
        <div class="">
          <div class="text-sm font-thin pt-4 pb-3 md:pt-1 cursor-pointer" @click="changeAboModalStore.toggle()">
            <span class="break-normal">{{ subTitles.join(', ') }}</span>
            <img :src="require('@/assets/icons/chevron_right.svg')" alt="" class="h-5 float-right">
          </div>
          <!--          <div class="text-sm font-thin pt-2 pb-4 cursor-pointer" @click="changeUserAddressStore.toggle()">-->
          <!--            Rechnungsdetails-->
          <!--            <img :src="require('@/assets/icons/chevron_right.svg')" alt="" class="h-5 float-right">-->
          <!--          </div>-->
          <div class="border-b border-black"></div>
          <!--          <div class="text-sm font-thin pt-2 pb-5 border-b border-black cursor-pointer">-->
          <!--            Zahlungsinformationen verwalten-->
          <!--            <img :src="require('@/assets/icons/chevron_right.svg')" alt="" class="h-5 float-right">-->
          <!--          </div>-->
        </div>
      </div>
    </div>
  </div>
  <change-sub-modal></change-sub-modal>
  <change-email-modal></change-email-modal>
  <change-password-modal></change-password-modal>
  <change-user-address-modal></change-user-address-modal>
</template>

<script>
import {useUserStore} from "@/store/user/user-store";
import {useChangeAboModalStore} from "@/store/modals/change-abo-modal-store";
import {useChangeEmailModalStore} from "@/store/modals/change-email-modal-store";
import {useChangePasswordModalStore} from "@/store/modals/change-password-modal-store";
import {useChangeUserAddressStore} from "@/store/modals/change-user-address-modal-store";
import ChangeSubModal from "@/components/modals/change_abo/ChangeSubModal";
import ChangeEmailModal from "@/components/modals/change_email/ChangeEmailModal";
import ChangePasswordModal from "@/components/modals/change_password/ChangePasswordModal";
import ChangeUserAddressModal from "@/components/modals/change_user_address/ChangeUserAddressModal";
import {ref} from "vue"
import Introduction from "@/components/theme/introduction/Introduction";

export default {
  name: 'Settings',
  components: {ChangeUserAddressModal, ChangePasswordModal, ChangeEmailModal, ChangeSubModal, Introduction},
  setup() {
    const userStore = useUserStore();
    const changeAboModalStore = useChangeAboModalStore();
    const changeEmailModalStore = useChangeEmailModalStore();
    const changePasswordModalStore = useChangePasswordModalStore();
    const changeUserAddressStore = useChangeUserAddressStore();
    const subTitles = ref([])

    Object.values(userStore.user.subscriptions).forEach(sub => {
      subTitles.value.push(sub.title)
    })

    return {
      subTitles,
      changeUserAddressStore,
      changePasswordModalStore,
      changeAboModalStore,
      userStore,
      changeEmailModalStore
    }
  }
}
</script>

<style>
</style>
