import {defineStore} from 'pinia'
import axios from "axios";
import {baseUrl} from "@/configurations/configurations";
import {useNotifyStore} from "@/store/notify/notify-store";

export const useChangePasswordModalStore = defineStore({
    id: 'changePasswordModalStore',
    state: () => ({
        isOpen: false,
        form: {
            oldPw: '',
            newPw: '',
            newPwRepeat: '',
        },
        errorMsg: ''
    }),
    getters: {},
    actions: {
        toggle() {
            this.isOpen = !this.isOpen;
        },
        changePw() {
            const notifyStore = useNotifyStore();
            const changePwInstance = axios.create({
                baseURL: baseUrl,
            });

            changePwInstance.post('api/change-password', {
                old_password: this.form.oldPw,
                new_password: this.form.newPw
            }).then((res) =>{
                this.errorMsg = '';

                this.resetForm();
                this.toggle();
                notifyStore.notify('Erfolgreich', res.data.message)
            }).catch((error) => {
                notifyStore.notify('Fehler', error.response.data.message, 'error')
            })
        },
        setErrorMsg(msg) {
            this.errorMsg = msg
        },
        resetForm() {
            this.form.oldPw = '';
            this.form.newPw = '';
            this.form.newPwRepeat = '';
        }
    },
})
