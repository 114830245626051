import {defineStore} from 'pinia'

export const useChangeEmailModalStore = defineStore({
    id: 'changeEmailModalStore',
    state: () => ({
        isOpen: false,
        form: {
            password: '',
            email: ''
        }
    }),
    getters: {},
    actions: {
        toggle() {
            this.isOpen = !this.isOpen;
        },
        reset(){
            this.form.password = '';
            this.form.email = '';
        }
    },
})
